import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import HowToReg from "@material-ui/icons/HowToReg";
import People from "@material-ui/icons/People";
import Nature from "@material-ui/icons/Nature";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Platform</h2>
          <h5 className={classes.description}>
            Our platform was designed to help companies in the EU market achieve
            their business objectives add flexibility to their resource
            management process
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Defining what we are looking for"
              description="We help companies
              identify exactly what specialists they need for the suitable time frame."
              icon={HowToReg}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Team Formation"
              description="Our team is always ready to
              help with the recruiting process, from helping write and assess
              the employee tests to the resource planing. We also take any
              possible worries regarding payment away from our Romanian
              customers."
              icon={People}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Helping People Grow"
              description="For our Nigerian specialists we offer coaching, help with the recruiting process, 
              attractive salaries, and various methods of compensation."
              icon={Nature}
              iconColor="success"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
